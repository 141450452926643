/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"

import VideoCard from "../VideoCard/VideoCard"

import { QUERY, COLORS } from "../../constants"

const VideoLista = () => {
  const data = useStaticQuery(graphql`
    query VideoQuery {
      strapiVideoGallery {
        videoCard {
          ... on StrapiComponentSharedVideoCard {
            id
            descrizione
            titolo
            video {
              file {
                publicURL
                  childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)


  const { videoCard } = data.strapiVideoGallery
  console.log(videoCard)
  const video = videoCard.map(
    ({ id, titolo, descrizione, video }) => (
      <VideoCard
        key={id}
        titolo={titolo}
        descrizione={descrizione}
        video={video}
      />
    )
  )

  return (
    <>
      <MainTitle>Video</MainTitle>
      <ListaVideo>{video}</ListaVideo>
    </>
  )
}

const MainTitle = styled.h1`
  margin-top: 4rem;
  color: ${COLORS.red};
  padding: 2rem 4.5rem;
  margin-bottom: 2rem;
  font-size: calc(3.2 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    font-size: calc(2.2 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(1.8 * var(--baseline-size));
    margin-bottom: 0;
  }

  @media ${QUERY.small} {
    padding: 2.5rem;
  }

  @media ${QUERY.smaller} {
    padding: 1.5rem;
  }

  @media ${QUERY.smallest} {
    padding: 1rem;
  }
`

const ListaVideo = styled.section`
  padding: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background: ${COLORS.lightBlue};
 
  @media ${QUERY.mediumSmall} {
    padding: 2rem;
 
  }

  @media ${QUERY.smaller} {
    padding: 1rem;

  }

  @media ${QUERY.smallest} {
    padding: 0.5rem;
  }
`

export default VideoLista
