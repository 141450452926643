import * as React from "react"
import { Helmet } from "react-helmet"
import HeaderSpazio from "../../components/HeaderSpazio/HeaderSpazio"
import FooterSpazio from "../../components/FooterSpazio"
import Navigator from "../../components/Navigator"
import VideoLista from "../../components/VideoLista"

import FontStyles from "../../components/FontStyles"
import GlobalStyles from "../../components/GlobalStyles"

const RicettePage = () => (
  <>
    <FontStyles />
    <GlobalStyles />
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <HeaderSpazio />
    <Navigator />
    <VideoLista />
    <FooterSpazio />
  </>
)

export default RicettePage
