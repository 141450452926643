/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react"
import Markdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"
import useCollapse from "react-collapsed"
import Video from "../Video/Video"

import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

const VideoCard = ({ titolo, descrizione, video }) => {
  const [isExpanded, setExpanded] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

  return (
    <Wrapper>
      <Titolo>{titolo}</Titolo>

<VideoWrapper>
        <Video url={video.file.publicURL} /></VideoWrapper>
   
      <section {...getCollapseProps()}>
        <Descrizione
          renderers={{
            heading: HeadingRenderer,
            link: LinkRenderer,
          }}
          children={descrizione}
        />
      </section>
      <Button
        {...getToggleProps({
          onClick: () => setExpanded(prevExpanded => !prevExpanded),
        })}
      >
        {" "}
        {isExpanded ? "chiudi" : "vedi descrizione"}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  min-width: 270px;
  margin: 0 2rem 2rem 0;
  padding: 2rem;
  position: relative;
  border-radius: 30px;
  border: 1px solid ${COLORS.blue};
  background: ${COLORS.white};

  @media ${QUERY.mediumSmall} {
    margin: 1rem auto;
  }
`
const VideoWrapper = styled.div`
  margin-left: -2rem;
`

const Button = styled.button`
  background: ${COLORS.lightBlue};
  color: ${COLORS.black};
  border: none;
  width: 100%;
  &:hover {
    background-color: ${COLORS.blue};
  }
`

const Titolo = styled.span`
  text-transform: uppercase;
  font-size: calc(1.6 * var(--baseline-size));
  font-weight: 100;
  display: block;
  margin-bottom: 0.5rem;

  @media ${QUERY.smaller} {
    font-size: calc(1.2 * var(--baseline-size));
  }
`

const Descrizione = styled(Markdown)`
  text-transform: lowercase;
  font-size: calc(0.9 * var(--baseline-size));
  font-weight: 400;

  p {
    margin: 0.5rem 0;
  }
`

export default VideoCard
